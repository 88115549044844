import { React, useState } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { Box, ThemeProvider } from "@mui/system";
import { theme } from "./theme";
import "./App.css";
import {
  IconA,
  LandingPage,
  MessageIcon,
  VectorA,
  VectorB,
  Pool,
  VectorC,
  VectorD,
  VectorE,
  VectorF,
  IconB,
  IconC,
  apple as Apple,
  playstore as Playstore,
  IconD,
  IconE,
  IconF,
} from "./assets";
import { Button, CssBaseline } from "@mui/material";
import { keyframes } from "@mui/system";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
// import required modules
import { FreeMode, Navigation } from "swiper";

import { InView } from "react-intersection-observer";

function App() {
  const [isInViewPort, setIsInViewPort] = useState(false);
  const [visibleIndices, setVisibleIndices] = useState([]);

  const toLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity:0;
  }
  to {
    transform: translateX(0%);
    opacity:1;

  }
`;

  const toRight = keyframes`
    from {
    transform: translateX(100%);
    opacity:0;
  }
  to {
    transform: translateX(0%);
    opacity:1;

  }
  `;

  let throttlePause;

  const handleScroll = (scroll) => {
    if (90 <= (scroll / window.innerHeight) * 100) {
      setIsInViewPort(true);
    } else {
      setIsInViewPort(false);
    }
  };

  const throttle = (callback) => {
    if (throttlePause) return;
    throttlePause = true;
    setTimeout(() => {
      callback(window.pageYOffset);
      throttlePause = false;
    }, 250);
  };

  window.addEventListener("scroll", () => {
    throttle(handleScroll);
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flex: "1 0 auto",
            minHeight: "100vh",
            background: `linear-gradient(to bottom, rgb(0, 0, 0,0.52),rgb(67, 67, 67,0.73)),url(${LandingPage}) no-repeat center center fixed`,
            webkitBackgroundSize: "cover",
            mozBackgroundSize: "cover",
            oBackgroundSize: "cover",
            backgroundSize: "cover",
          }}
        >
          <Grid
            xs={12}
            item
            container
            sx={{
              position: "fixed",
              left: 0,
              top: 0,
              height: [40, 65],
              alignItems: "baseline",
              zIndex: 3000,
              "&.MuiGrid-item": {
                pt: 1,
              },

              ...(isInViewPort && {
                background: "#1D293F",
                // theme.palette.background.paper,
                transition: "background 1s",
              }),
            }}
          >
            <Grid item xs={12} sm={12} md={8}>
              {/* <Box
                sx={{
                  display: "flex",
                  columnGap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: [26, 50],
                    height: [26, 50],
                    borderRadius: "50%",
                    borderColor: "transparent",
                    background: "#080FB3",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Sen",
                      fontWeight: 800,
                      fontSize: [20, 35],
                      color: "#ffffff",
                    }}
                  >
                    P
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: '"Nunito Sans",sans-serif',
                      fontWeight: 900,
                      fontSize: [20, 35, 40],
                      color: "#ffffff",
                    }}
                  >
                    Delivery Pool
                  </Typography>
                </Box>
              </Box> */}


              <Box sx={{ display: "flex", flexGrow: 3, alignItems: "center" }}>
                <Box
                  sx={{
                    width: "130px",
                    height: "42px",
                    "&:hover": {
                      pointer: "cursor",
                    },
                  }}
                  
                >
                  <Pool width={"100%"} height={"100%"} />
                </Box>
              </Box>


            </Grid>
            <Grid
              item
              container
              xs={4}
              sx={{ display: ["none", "none", "flex"] }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    "&.MuiButton-textPrimary": {
                      fontSize: {
                        md: "0.53rem",
                        lg: "0.875rem",
                      },
                    },
                  }}
                >
                  Our Solution
                </Button>
                <Button
                  variant="text"
                  sx={{
                    "&.MuiButton-textPrimary": {
                      fontSize: {
                        md: "0.53rem",
                        lg: "0.875rem",
                      },
                    },
                  }}
                >
                  How it works
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    "&.MuiButton-containedPrimary": {
                      fontSize: {
                        md: "0.53rem",
                        lg: "0.875rem",
                      },
                    },
                  }}
                >
                  Join the waitlist
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            sx={{
              "&.MuiGrid-item": {
                pt: [7, 10],
              },
            }}
          >
            <Grid container rowGap={4}>
              <Grid item xs={12} container justifyContent="center">
                <Typography
                  sx={{
                    fontFamily: '"Nunito Sans",sans-serif',
                    fontWeight: 900,
                    fontSize: [35, 35, 50, 60, 70],
                    color: "#ffffff",
                    textAlign: { xs: "center", md: "start" },
                  }}
                >
                  Your packages. Delivered better.
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Box
                  sx={{
                    height: 2,
                    width: 167,
                    border: "3px solid #080FB3",
                    transform: "rotate(3deg)",
                  }}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 700,
                    fontSize: [12, 18, 24, 30],
                    color: "#ffffff",
                  }}
                >
                  One platform to meet all your delivery needs
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
                container
                justifyContent="center"
              >
                <Button variant="contained"> get early access</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <InView rootMargin="100px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid
                ref={ref}
                container
                alignItems="center"
                rowSpacing={5}
                sx={{ mt: 4 }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  sx={{
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                    px: [2, 4, 6],
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Circular Std, sans-serif",
                        fontWeight: 700,
                        fontSize: [18, 20, 32, 48],
                        color: "#1D293F",
                        textAlign: { xs: "center", md: "start" },
                      }}
                    >
                      On-demand, express, same day, next day and 1 - 3 days
                      door-to-door courier service
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  sx={{
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                    px: [2, 4, 6],
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Circular Std, sans-serif",
                      fontWeight: 400,
                      color: "#7C8087",
                      fontSize: [10, 14, 16, 18],
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    Our technology supports delivery optimization in a way that
                    increases efficiency and saves cost. 
                    Depending on your delivery needs and budget, we will match your rider with the
                    best-suited delivery agent and courier your parcel at the lowest cost.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Swiper
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                      },
                      900: { slidesPerView: 4, spaceBetween: 30 },
                    }}
                    grabCursor={true}
                    navigation
                    longSwipes={false}
                    spaceBetween={30}
                    freeMode
                    onTouchMove={(swiperInstance) => {
                      if (swiperInstance.visibleSlidesIndexes.length === 5) {
                        const slideItemsToBlur = [
                          swiperInstance.visibleSlidesIndexes[0],
                          swiperInstance.visibleSlidesIndexes[
                            swiperInstance.visibleSlidesIndexes.length - 1
                          ],
                        ];

                        setVisibleIndices(slideItemsToBlur);
                      } else {
                        setVisibleIndices([]);
                      }
                    }}
                    onReachBeginning={() => setVisibleIndices([])}
                    onReachEnd={() => setVisibleIndices([])}
                    modules={[FreeMode, Navigation]}
                    className="mySwiper"
                    watchSlidesProgress
                  >
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(0) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.8)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              p: [2, 3, 4],
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <MessageIcon height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                >
                                  Manage undelivered orders with ease
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                >
                                  Decrease Return to Origin (RTO) for
                                  unsuccessful orders and process non-delivery
                                  reports (NDR) quickly
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(1) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.8)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              p: [2, 3, 4],
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <IconD height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                >
                                  Unlimited orders, one platform
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                >
                                  Pool acts as the singular touchpoint between
                                  you and 20+ carriers so you can manage your
                                  orders faster and with more efficiency
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(2) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.8)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              p: [2, 3, 4],
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <MessageIcon height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                >
                                  Automated carrier selection
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                >
                                  Process high-volume deliveries faster than
                                  ever Pool exchange engine that selects an
                                  ideal carrier for your needs
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(3) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.8)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              p: [2, 3, 4],
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <IconE height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                >
                                  Real-time tracking
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                >
                                  Get real-time email and SMS alerts to track
                                  your shipments.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(4) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.8)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              p: [2, 3, 4],
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <IconF height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: 16,
                                    fontWeight: 700,
                                  }}
                                >
                                  Exhaustive performance reports
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flex: 2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: 12,
                                    fontWeight: 400,
                                  }}
                                >
                                  Monitor shipments, billing cycles,
                                  remittances, and evaluate your business growth
                                  on the pool dashboard.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  </Swiper>
                </Grid>
              </Grid>
            );
          }}
        </InView>
        <Grid container justifyContent="center" sx={{ my: 5 }}>
          <Grid
            item
            xs={10}
            md={8}
            container
            sx={{ backgroundColor: "#344EA9", borderRadius: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                px: [2, 4, 6],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: [3, 3, 2],
                  alignItems: "center",
                  justifyContent: "center",
                  height: [100, 120, 150],
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Sen",
                    fontWeight: 600,
                    fontSize: [10, 12, 14, 18],
                    color: "#ffffff",
                  }}
                >
                  It’s almost ready for you, Be the first to try the Pool
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#F8CC64",
                    color: "#000000",
                    textTransform: "initial",
                    "&.MuiButton-containedPrimary": {
                      fontSize: {
                        xs: "0.40rem",
                        md: "0.53rem",
                        lg: "0.75rem",
                      },
                    },
                    "&:hover": {
                      backgroundColor: "#FADC8A",
                      color: "#ffffff",
                    },
                  }}
                  variant="contained"
                >
                  get early access
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <InView rootMargin="-200px 0px 100px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid ref={ref} container sx={{ my: 5 }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: ["none", "none", "block"],

                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Grid container justifyContent="center">
                    <Box
                      component="img"
                      sx={{
                        height: 500,
                        width: 440,
                        maxHeight: { xs: 300, md: 350 },
                        maxWidth: { xs: 350, md: 400 },
                      }}
                      src={require("./assets/image3.png")}
                      alt="why pool?"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  rowSpacing={2}
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{
                    px: [2, 4, 6],
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: '"Circular Std",sans-serif',
                        fontWeight: 700,
                        fontSize: [14, 16, 18, 24],
                      }}
                    >
                      Why Pool?
                    </Typography>
                  </Grid>

                  <Grid item container columnSpacing={1}>
                    <Grid item xs={3}>
                      <Box
                        component="img"
                        sx={{
                          height: [52, 63, 74],
                          width: [52, 63, 74],
                        }}
                        src={require("./assets/WhyPoolA.png")}
                        alt="why pool?"
                      />
                    </Grid>
                    <Grid item xs={9} container>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 700,
                            fontSize: [12, 14, 16, 18],
                          }}
                        >
                          All in One Logistics Platform
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 400,
                            fontSize: [10, 12, 14],
                            color: "#7C8087",
                          }}
                          component="div"
                        >
                          Save both time and cost with our igital dashboard and
                          wide variety of elivery services.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container columnSpacing={1}>
                    <Grid item xs={3}>
                      <Box
                        component="img"
                        sx={{
                          height: [52, 63, 74],
                          width: [52, 63, 74],
                        }}
                        src={require("./assets/WhyPoolB.png")}
                        alt="why pool?"
                      />
                    </Grid>
                    <Grid item xs={9} container>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 600,
                            fontSize: [12, 14, 16, 18],
                          }}
                          component="div"
                        >
                          Guaranteed Delivery with eal-Time Tracking
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 400,
                            fontSize: [10, 12, 14],

                            color: "#7C8087",
                          }}
                          component="div"
                        >
                          We will deliver to wherever you need us. Track exactly
                          where your parcel is with our 24/7 tracking
                          capability.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container columnSpacing={1}>
                    <Grid item xs={3}>
                      <Box
                        component="img"
                        sx={{
                          height: [52, 63, 74],
                          width: [52, 63, 74],
                        }}
                        src={require("./assets/WhyPoolC.png")}
                        alt="why pool?"
                      />
                    </Grid>
                    <Grid item xs={9} container>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 600,
                            fontSize: [12, 14, 16, 18],
                          }}
                          component="div"
                        >
                          Flexibility
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: '"Circular Std",sans-serif',
                            fontWeight: 400,
                            fontSize: [10, 12, 14],

                            color: "#7C8087",
                          }}
                          component="div"
                        >
                          Our scalable technology and supply can meet your
                          business needs during both high and low seasons!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </InView>

        <InView rootMargin="70px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid
                container
                ref={ref}
                direction="column"
                spacing={4}
                sx={{ my: 5, backgroundColor: "#F7F8FA", px: [2, 4, 6] }}
              >
                <Grid
                  item
                  xs={12}
                  direction="column"
                  container
                  sx={{
                    mt: 6,
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Circular Std",sans-serif',
                      fontWeight: 700,
                      fontSize: [18, 22, 24],
                      textAlign: [null, null, "center"],
                    }}
                    gutterBottom
                    component="div"
                  >
                    Less hassle, more shipments
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: '"Circular Std",sans-serif',
                      fontWeight: 400,
                      fontSize: [10, 12, 14],
                      color: "#7C8087",
                      textAlign: [null, null, "center"],
                    }}
                    component="div"
                  >
                    Pool helps businesses focus on growth by making order
                    fulfillment optimized and streamlined
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    mb: 8,
                    justifyContent: { md: "center" },
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(12,1fr)",
                        md: "repeat(3,1fr)",
                      },
                      gridTemplateRows: "min-content",
                      gap: { xs: "16px 0px", md: "16px 32px" },
                    }}
                  >
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorA width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 14,
                            }}
                          >
                            No set up fee
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Register for free. o upfront cost for set up.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorB width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            White-labelled Solutions
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Leverage our shipping ech. Re-brand as yours.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorC width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Plugins &amp Integrations
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            One API for all carriers and sync all your orders
                            from your stores &amp marketplaces.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorD width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Real-time Tracking
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Auto generate your labels with one click with auto
                            tracking notifications on email.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorE width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Multi Carrier Pool
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Choose from a plethora of Carrier options to match
                            your shipping needs.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <VectorF width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Dedicated Customer Service
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Call or mail us and get your queries answered by our
                            experienced team.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            );
          }}
        </InView>

        <InView rootMargin="100px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid container ref={ref} sx={{ my: 5 }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  spacing={2}
                  sx={{
                    px: [2, 4, 6],
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: '"Circular Std",sans-serif',
                        fontSize: [16, 18, 24],
                        fontWeight: 700,
                      }}
                    >
                      Pool for Business
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: '"Circular Std",sans-serif',
                        color: "#7C8087",
                        fontSize: [12, 14, 16, 18],
                        fontWeight: 400,
                      }}
                      component="div"
                    >
                      Our technology-enabled dashboard is made for both small
                      and large businesses. If you have a website, use our
                      integration for automated order fulfilment.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconA width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        Bulk Delivery
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconB width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        Photo Proof of Delivery
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconC width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        Insurance Cover
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconA width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        24/7 GPS Tracking
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconB width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        Cash on delivery
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        columnGap: 1,
                        mb: 1,
                      }}
                    >
                      <IconC width={"45px"} height={"45px"} />
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [10, 12, 14, 16],
                          fontWeight: 600,
                        }}
                      >
                        Returns Exchanges
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  sx={{
                    position: "relative",
                    display: { xs: "none", sm: "none", md: "block" },
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      position: "absolute",
                      right: [null, null, 280, 350],
                      top: 0,
                      height: [null, null, 250, 300],
                      width: [null, null, 250, 300],
                    }}
                    alt="Oval"
                    src={require("./assets/Oval.png")}
                  />
                  <Box
                    component="img"
                    sx={{
                      position: "absolute",
                      transform: [
                        null,
                        null,
                        "translateY(45px)",
                        "translateY(70px)",
                      ],
                      right: 0,
                      height: [null, null, 340, 400],
                      width: [null, null, 480, 600],
                    }}
                    alt="Group"
                    src={require("./assets/Group.png")}
                  />
                </Grid>
              </Grid>
            );
          }}
        </InView>
        <InView rootMargin="100px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid ref={ref} container sx={{ my: 5 }}>
                <Grid
                  item
                  xs={6}
                  md
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    // position: "relative",
                    margin: "auto",
                    display: { xs: "none", sm: "none", md: "flex" },
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateRows: {
                        md: "75px 162.5px 162.5px",
                        lg: "100px 200px 200px",
                      },
                      gridTemplateColumns: "auto",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        gridRow: "2 / span 1",
                        gridColumn: "1",
                        // position: "absolute",
                        height: [null, null, 250, 300],
                        width: [null, null, 250, 300],
                        // top: "50%",
                        // left: "50%",
                        // transform: "translate(-50%,-50%)",
                      }}
                      alt="Oval"
                      src={require("./assets/OvalTwo.png")}
                    />
                    <Box
                      component="img"
                      sx={{
                        gridRow: "1 /span 2",
                        gridColumn: "1",
                        // position: "absolute",
                        height: [null, null, 420, 500],
                        width: [null, null, 225, 275],
                        // top: "50%",
                        // left: "50%",
                        // transform: "translate(-50%,-50%)",
                      }}
                      alt="Group"
                      src={require("./assets/MobilePhone.png")}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                  sx={{
                    px: [2, 4, 6],
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Box sx={{ width: "100%", margin: "auto" }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontSize: [16, 18, 24],
                          fontWeight: 700,
                        }}
                      >
                        Manage requests from anywhere with any device
                      </Typography>
                    </Grid>
                    <Box sx={{ mb: [1, 1, 2] }} />
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          color: "#7C8087",
                          fontSize: [12, 14, 16, 18],
                          fontWeight: 400,
                        }}
                        component="div"
                      >
                        Send in or manage requests from any where as long as you
                        have a connected device.
                      </Typography>
                    </Grid>
                    <Box sx={{ mb: [1, 1, 2] }} />
                    <Grid
                      item
                      container
                      xs={12}
                      rowSpacing={{ xs: 1, md: 2, lg: 4 }}
                    >
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#0F53FA",
                            borderRadius: " 5px",
                            p: 1,
                            "&:hover": {
                              pointer: "cursor",
                            },
                          }}
                        >
                          <Box>
                            <Apple width={"45px"} height={"45px"} />
                          </Box>
                          <Box sx={{ mx: 1 }} />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Typography
                                sx={{
                                  fontFamily: '"Circular Std",sans-serif',
                                  fontSize: [8, 10, 12],
                                  fontWeight: 400,
                                  color: "#E9F7FE",
                                }}
                              >
                                Download on the
                              </Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <Typography
                                sx={{
                                  fontFamily: '"Circular Std",sans-serif',
                                  fontSize: [10, 12, 14, 16],
                                  fontWeight: 600,
                                  color: "#E9F7FE",
                                }}
                              >
                                App Store
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={6} />
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#ffffff",
                            border: "2px solid #E2E4E8",
                            borderRadius: " 5px",
                            p: 1,
                            "&:hover": {
                              pointer: "cursor",
                            },
                          }}
                        >
                          <Box>
                            <Playstore width={"45px"} height={"45px"} />
                          </Box>
                          <Box sx={{ mx: 1 }} />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Typography
                                sx={{
                                  fontFamily: '"Circular Std",sans-serif',
                                  fontSize: [8, 10, 12],
                                  fontWeight: 400,
                                }}
                              >
                                Get it on
                              </Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <Typography
                                sx={{
                                  fontFamily: '"Circular Std",sans-serif',
                                  fontSize: [10, 12, 14, 16],
                                  fontWeight: 600,
                                }}
                              >
                                Google Play
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={6} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            );
          }}
        </InView>

        <Grid
          container
          sx={{ my: 5, px: [2, 4, 6] }}
          rowSpacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Circular Std",sans-serif',
                fontSize: [16, 18, 24, 32],
                fontWeight: 700,
              }}
            >
              Our Services
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: "100" }} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={2}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontSize: [12, 14, 16],
                  fontWeight: 700,
                }}
              >
                Express Delivery
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  color: "#7C8087",
                  fontSize: [10, 12, 14],
                  fontWeight: 400,
                }}
                component="div"
              >
                We deliver in 1-2 hours Door-to-Door On-Demand No Minimum
                Quantity Needed
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              md={6}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  textTransform: "initial",
                  "&.MuiButton-containedPrimary": {
                    fontSize: {
                      xs: "0.40rem",
                      md: "0.53rem",
                      lg: "0.75rem",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#6690FF",
                    color: "#ffffff",
                  },
                }}
                variant="contained"
              >
                Start 7 Days Free Trial
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: "100%" }} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={2}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontSize: [12, 14, 16],
                  fontWeight: 700,
                }}
              >
                4-Hour Delivery
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  color: "#7C8087",
                  fontSize: [10, 12, 14],
                  fontWeight: 400,
                }}
                component="div"
              >
                We deliver in 4 hours Door-to-Door On-Demand No Minimum Quantity
                Needed
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              md={6}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  textTransform: "initial",
                  "&.MuiButton-containedPrimary": {
                    fontSize: {
                      xs: "0.40rem",
                      md: "0.53rem",
                      lg: "0.75rem",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#6690FF",
                    color: "#ffffff",
                  },
                }}
                variant="contained"
              >
                Start 7 Days Free Trial
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: "100%" }} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={2}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontSize: [12, 14, 16],
                  fontWeight: 700,
                }}
              >
                Same Day Delivery
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  color: "#7C8087",
                  fontSize: [10, 12, 14],
                  fontWeight: 400,
                }}
                component="div"
              >
                Warehouse Storage &amps Delivery Door-to-Door Minimum Quantity
                Needed Submit order by 11am for delivery before 10pm on the same
                day
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              md={6}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Button variant="contained">Start 7 Days Free Trial</Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: "100%" }} />
          </Grid>
          <Grid item container>
            <Grid item xs={12} md={2}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontSize: [12, 14, 16],
                  fontWeight: 700,
                }}
              >
                Next/1 - 3 Days Delivery
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  color: "#7C8087",
                  fontSize: [10, 12, 14],
                  fontWeight: 400,
                }}
                component="div"
              >
                Warehouse Storage &amps Delivery Door-to-Door Minimum Quantity
                Needed
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              md={6}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Button variant="contained">Start 7 Days Free Trial</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ my: 5, px: [2, 4, 6] }}
          rowSpacing={{ xs: 2, md: 4 }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Circular Std",sans-serif',
                fontSize: [16, 18, 24],
                fontWeight: 700,
              }}
            >
              Get early Access
            </Typography>
          </Grid>
          <Grid
            item
            container
            rowSpacing={{ xs: 1, md: 2 }}
            columnSpacing={{ md: 4 }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                sx={{ display: "flex" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                sx={{ display: "flex" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ display: "flex" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Phone No"
                variant="outlined"
                sx={{ display: "flex" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ px: [1, 2, 4] }}>
            <Typography
              sx={{
                fontFamily: '"Circular Std",sans-serif',
                color: "#8C97AC",
                fontSize: [10, 12, 14],
                fontWeight: 300,
              }}
              component="div"
            >
              By submitting this form, I agree to receive emails, telephone
              calls, promotional offers, marketing materials and other
              communications from “POOL” (including its subsidiaries,
              affiliates, agents, and assigns) (“POOL”) about its products and
              services. I understand that I can withdraw my authorization at any
              time. I also understand that my information will not be shared
              with any other parties outside of POOL.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              sx={{
                backgroundColor: "#000000",
                color: "#ffffff",
                textTransform: "initial",
                "&.MuiButton-containedPrimary": {
                  fontSize: {
                    xs: "0.40rem",
                    md: "0.53rem",
                    lg: "0.75rem",
                  },
                },
                "&:hover": {
                  backgroundColor: "#B2B2B2",
                  color: "#000000",
                },
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 5,
            background: "#1D293F",
            color: "#ffffff",
            height: {
              xs: "auto",
              md: "300px",
            },
          }}
        >
          <Grid item md={2} sx={{ display: { xs: "none", md: "flex" } }}></Grid>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={8}
            sx={{ height: { xs: "auto", md: "120px" } }}
          >
            <Grid item container md={4} rowSpacing={{ md: 1 }}>
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                      width: [18, 30],
                      height: [18, 30],
                      borderRadius: "50%",
                      borderColor: "transparent",
                      background: "#ffffff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Sen",
                        fontWeight: 800,
                        fontSize: [16, 24],
                        color: "#1D293F",
                      }}
                    >
                      P
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: '"Nunito Sans",sans-serif',
                        fontWeight: 800,
                        fontSize: [20, 24, 26],
                        color: "#ffffff",
                      }}
                    >
                      Pool
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                  component="div"
                >
                  Pool’s industry-leading platform helps businesses simplify
                  shipping by dispatching, managing and tracking orders through
                  a single dashboard
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Button variant="contained">Ask Question</Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={8}
              sx={{ height: { xs: "auto", md: "70px" }, pl: { md: 9 } }}
            >
              {/* <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid> */}
              {/* <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontSize: 8,
                    fontWeight: 300,
                  }}
                >
                  community
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item md={2} sx={{ display: { xs: "none", md: "flex" } }}></Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default App;
